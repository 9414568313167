<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.warehouseRentCollectionReport') }}</h4>
      </template>
      <template v-slot:body>
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
            >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('org_pro_division.division')"
                label-for="division_id"
            >
              <b-form-select
                  plain
                  v-model="search.division_id"
                  :options="divisionList"
                  id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionLists"
                  id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
            >
              <b-form-select
                  plain
                  v-model="search.district_id"
                  :options="districtList"
                  id="district_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
            >
              <b-form-select
                  plain
                  v-model="search.upazilla_id"
                  :options="upazilaList"
                  id="upazilla_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
            >
              <b-form-select
                  plain
                  v-model="search.union_id"
                  :options="unionList"
                  id="union_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_type_id"
                :label="$t('warehouse_config.warehouse_type')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_type_id"
                  :options="warehouseTypeList"
                  id="warehouse_type_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="warehouse_category_id"
                :label="$t('warehouse_config.warehouse_category')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_category_id"
                  :options="warehouseCategoryList"
                  id="warehouse_category_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-if="!authUser.warehouse_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.from_date')"
                label-for="from_date"
            >
              <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.to_date')"
                label-for="to_date"
            >
            <flat-pickr class="form-control"
                        v-model="search.to_date"
                        :placeholder="$t('globalTrans.select_date')"
            ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-button type="button" class="float-right" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.warehouseRentCollectionReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Warehouse Rent Collection Report Sheet"
                  name="warehouse-rent-collection-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                   <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.warehouseRentCollectionReport')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.organization') }}: </strong> {{ getOrganizationName(search.org_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                  </b-col>
                  <b-col md="3">
                    <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                  </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_category') }}: </strong> {{ getWarehouseCategoryName(search.warehouse_category_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.from_date') }}: </strong> {{ getSearchDate(search.from_date) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.to_date') }}: </strong> {{ getSearchDate(search.to_date) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="wareReportList" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(contact_no)="data">
                      <span>{{ $n(0, { useGrouping: false }) }}{{ $n(data.item.contact_no, { useGrouping: false }) }}</span>
                    </template>
                      <template v-slot:cell(collected_amount)="data">
                          <span>{{ $n(data.item.collected_amount) }}</span>
                      </template>
                      <template v-slot:cell(due_amount)="data">
                          <span>{{ $n(data.item.due_amount) }}</span>
                      </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template #custom-foot="">
                      <b-tr>
                        <b-th class="text-right" colspan="4"><span>{{ $t('sitePreference.grossTotal') }}</span></b-th>
                        <b-th class="text-right"><span>{{ $n(totalCollectedAmount) }}</span></b-th>
                        <b-th class="text-right"><span>{{ $n(totalDueAmount) }}</span></b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, warehouseRentCollectionReportApi } from '../../api/routes'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  "Farmer's Name": 'farmerName',
  'Contact No': 'contactNo',
  Address: 'addressInfo',
  'Collected Amount(Tk)': 'collectedAmount',
  'Due Amount(Tk)': 'dueAmount'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'কৃষকের নাম': 'farmerName',
  'যোগাযোগের নম্বর': 'contactNo',
  ঠিকানা: 'addressInfo',
  'সংগৃহীত পরিমাণ(টাকা)': 'collectedAmount',
  'বাকি পরিমাণ(টাকা)': 'dueAmount'
}

export default {
  components: {
    ListReportHead
  },
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        warehouse_category_id: 0,
        from_date: '',
        to_date: ''
      },
      organization: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      tempList: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      farmerList: [],
      totalCollectedAmount: 0,
      totalDueAmount: 0,
      districtLoading: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
      var serial = 0
      const customizeData = this.wareReportList.map(item => {
        serial += 1
        item.serial = this.$n(serial)
        item.farmerName = this.currentLocale === 'bn' ? item.farmer_name_bn : item.farmer_name
        item.contactNo = this.$n(item.contact_no, { useGrouping: false })
        item.addressInfo = this.currentLocale === 'bn' ? item.address_bn : item.address
        item.collectedAmount = this.$n(item.collected_amount)
        item.dueAmount = this.$n(item.due_amount)

        return Object.assign({}, item)
      })
      const item = {}
      item.serial = ''
      item.farmerName = ''
      item.contactNo = ''
      item.addressInfo = this.$t('sitePreference.grossTotal')
      item.collectedAmount = this.$n(this.totalCollectedAmount)
      item.dueAmount = this.$n(this.totalDueAmount)
      customizeData.push(item)

      return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.organization') + ' : ' + this.getOrganizationName(this.search.org_id) + ' ' + this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id)
      const secondRow = this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id) + ' ' + this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_config.warehouse_category') + ' : ' + this.getWarehouseCategoryName(this.search.warehouse_category_id)
      const thirdRow = this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + ' ' + this.$t('globalTrans.from_date') + ' : ' + this.getSearchDate(this.search.from_date) + ' ' + this.$t('globalTrans.to_date') + ' : ' + this.getSearchDate(this.search.to_date)
      return [this.$t('warehouse_report.warehouseRentCollectionReport'), '', firstRow, secondRow, thirdRow, '']
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return listObject
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList
    },
    warehouseCategoryList: function () {
      return this.$store.state.warehouse.warehouseCategoryList
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('warehouse_report.farmer_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.contactNo'), class: 'text-left' },
        { label: this.$t('warehouse_report.address'), class: 'text-left' },
        { label: this.$t('warehouse_report.collectedAmount'), class: 'text-right' },
        { label: this.$t('warehouse_report.dueAmount'), class: 'text-right' }
      ]

      let keys = []

      keys = [
        { key: 'index' },
        { key: this.currentLocale === 'bn' ? 'farmer_name_bn' : 'farmer_name' },
        { key: 'contact_no' },
        { key: this.currentLocale === 'bn' ? 'address_bn' : 'address' },
        { key: 'collected_amount' },
        { key: 'due_amount' }
      ]

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.districtList = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
  },
  created () {
    this.loadData()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    searchData () {
      this.showData = true
      // if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showHeading = true
      // }
    },
    isEmpty (obj) {
      return Object.keys(Object.keys(obj).length === 0 && obj.constructor === Object).length === 0 && Object.keys(obj).length === 0 && obj.constructor === Object.constructor === Object
    },
    async loadData () {
      this.wareReportList = []
      this.totalCollectedAmount = 0
      this.totalDueAmount = 0
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
      if (response.success) {
        this.farmerList = response.data
      }
      RestApi.getData(warehouseServiceBaseUrl, warehouseRentCollectionReportApi, this.search).then(response => {
        if (response.success) {
          const finalData = []
          const obj = response.data
          if (!this.isEmpty(response.data)) {
            for (const prop in response.data) {
              if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                const item = obj[prop]
                item.farmer_id = prop
                finalData.push(item)
              }
            }
          }
          this.dataList(finalData)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    },
    dataList (data) {
      let totalCollectedAmount = 0
      let totalDueAmount = 0
      const wareReportData = data.map(item => {
        const farmerObject = this.farmerList.find(farmer => farmer.mobile_no === item.farmer_id)
        const farmerData = {
          farmer_name: farmerObject !== undefined ? farmerObject.name : '',
          farmer_name_bn: farmerObject !== undefined ? farmerObject.name_bn : '',
          contact_no: farmerObject !== undefined ? farmerObject.mobile_no : '',
          address: farmerObject !== undefined ? farmerObject.address : '',
          address_bn: farmerObject !== undefined ? farmerObject.address_bn : ''
        }
        totalCollectedAmount += item.collected_amount
        totalDueAmount += item.due_amount
        return Object.assign({}, item, farmerData)
      })
      this.wareReportList = wareReportData
      this.totalCollectedAmount = totalCollectedAmount
      this.totalDueAmount = totalDueAmount
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
      this.districtLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.farmer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.contactNo'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.address'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.collectedAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.dueAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Rent Collection Report' : 'গুদাম ভাড়া সংগ্রহের প্রতিবেদন'
      const columnWids = ['7%', '15%', '15%', '23%', '15%', '15%']
      const header1 = [
        { text: `${this.$t('globalTrans.organization')} : ${this.getOrganizationName(this.search.org_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_category')} : ${this.getWarehouseCategoryName(this.search.warehouse_category_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header3 = [
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.from_date')} : ${this.getSearchDate(this.search.from_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.to_date')} : ${this.getSearchDate(this.search.to_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2, header3]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'warehouse-rent-collection-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'farmer_name' : 'farmer_name_bn' },
          { key: 'contact_no' },
          { key: this.$i18n.locale === 'en' ? 'address' : 'address_bn' },
          { key: 'collected_amount' },
          { key: 'due_amount' }
        ]
      var serial = 0
      const listData = this.wareReportList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'collected_amount') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'due_amount') {
              return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'contact_no') {
                return { text: this.$n(item[keyItem.key], { useGrouping: false }) }
            }

          return { text: item[keyItem.key] }
        })
        return rowData
      })

      const total = [
        { text: '' },
        { text: '' },
        { text: '' },
        this.currentLocale === 'en' ? { text: 'GrossTotal :' } : { text: 'সর্ব মোট :' },
        { text: this.$n(this.totalCollectedAmount) },
        { text: this.$n(this.totalDueAmount) }
      ]
      listData.push(total)

      return listData
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseCategoryName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.warehouseCategoryList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getOrganizationName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.organizationtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getSearchDate (date) {
      let dateValue = this.$t('globalTrans.all')
      if (date) {
        dateValue = this.$options.filters.dateFormat(date)
      }
      return dateValue
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
